/* eslint no-underscore-dangle: 0 */
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Line, mixins } from 'vue-chartjs';
import Chart, {
    ChartData, ChartOptions, ChartTooltipOptions, ChartPluginsOptions, ChartDataSets, ChartPoint,
} from 'chart.js';

export interface TooltipModel extends ChartTooltipOptions {
    opacity: number,
    caretX: number,
    title: string[]
}

@Component({
    extends: Line,
    mixins: [mixins.reactiveProp],
})
export default class Graph extends Vue {
    @Prop({ required: true, type: Object as () => ChartData })
    chartData!: ChartData;

    @Prop({ required: true, type: Object as () => ChartOptions })
    options!: ChartOptions;

    @Prop({ required: false, type: Array })
    // Name of plugins that should be 'on'
    plugins!: string[];

    @Prop({ required: false, type: Number, default: null })
    // Needed for canvasFixedTooltipPlugin
    fixedTooltipPosition!: number | null;

    @Watch('fixedTooltipPosition')
    onFixedTooltipPositionChange() {
        if (!this.plugins.length && !this.plugins.find(p => p === 'canvasFixedTooltipPlugin')) {
            return;
        }

        this.drawChart();
    }

    @Watch('options')
    onOptionsChanged() {
        this.drawChart();
    }

    renderChart!: (chartData: ChartData, options: ChartOptions) => void;
    addPlugin!: (plugin: ChartPluginsOptions) => void;

    mounted() {
        const pluginSet = {} as {
            [pluginName: string]: {
                id: string,
                afterDraw?: (char: ChartPluginsOptions) => void,
                beforeUpdate?: (char: ChartPluginsOptions) => void,
                afterDatasetsDraw?: (char: ChartPluginsOptions) => void,
            }
        };

        // TODO add all plugins to pluginSet and apply only plugins that is in this.plugins prop

        const plugin = {
            id: 'metricsForPopupPlugin',
            afterDraw: (chartInstance: ChartPluginsOptions) => {
                // There are can be some unrecognized errors, because its interfering in chart.js logic, although we have enough condition above
                try {
                    const barWidth = chartInstance.getDatasetMeta(1).data[0]._model.x;
                    const barWidth2 = chartInstance.getDatasetMeta(1).data[1]._model.x;

                    const widthDivision = barWidth2 - barWidth;

                    const startPixel = chartInstance.scales[chartInstance.config.options.scales.xAxes[0].id]._startPixel;

                    const payload = `${widthDivision}|${startPixel}`;

                    this.$emit('input', payload);
                    // eslint-disable-next-line no-empty
                } catch (err) {}
            },
        };

        const dashedLine = {
            id: 'dashedLinePlugin',
            afterDatasetsDraw: (chart: ChartPluginsOptions) => {
                const ctx = chart.ctx as CanvasRenderingContext2D;
                const { datasets } = chart.config.data;
                const xAxis = chart.scales[chart.config.options.scales.xAxes[0].id];
                const yAxisDefault = chart.scales[chart.config.options.scales.yAxes[0].id];

                let yFrom: number | null;
                let yTo: number | null;
                let xFrom: number | null;
                let xTo: number | null;

                datasets.forEach((element: ChartDataSets) => {
                    if (element.hidden) return;
                    const yAxis = chart.scales[element.yAxisID!] || yAxisDefault;

                    const { data } = element;
                    if (!data) return;

                    const jsonData = JSON.stringify(data);
                    const sourceData = JSON.parse(jsonData);
                    const lastIndexElement = data.length - 1;

                    for (let i = 0; i < data.length; i++) {
                        const coordinate: any = data[i];

                        if (coordinate !== null) {
                            yFrom = yAxis.getPixelForValue(data[i]);
                            xFrom = xAxis.getPixelForValue(chart.config.data.labels[i]);
                            yTo = yAxis.getPixelForValue(data[i]);
                            xTo = xAxis.getPixelForValue(chart.config.data.labels[i]);
                        }

                        if (i === 0 && coordinate === null) {
                            const findIndex = sourceData.findIndex((item: number | null) => item !== null);
                            if (findIndex !== -1) {
                                yFrom = yAxis.getPixelForValue(data[findIndex]);
                                xFrom = xAxis.getPixelForValue(chart.config.data.labels[0]);
                                yTo = yAxis.getPixelForValue(data[findIndex]);
                                xTo = xAxis.getPixelForValue(chart.config.data.labels[0]);
                            }
                        }

                        if (i === lastIndexElement && coordinate === null) {
                            xTo = xAxis.getPixelForValue(chart.config.data.labels[lastIndexElement]);
                        }

                        if (coordinate === null && i !== 0) {
                            if (data[i - 1] !== null) {
                                yFrom = yAxis.getPixelForValue(data[i - 1]);
                                xFrom = xAxis.getPixelForValue(chart.config.data.labels[i - 1]);
                            }

                            if (data[i + 1]) {
                                yTo = yAxis.getPixelForValue(data[i + 1]);
                                xTo = xAxis.getPixelForValue(chart.config.data.labels[i + 1]);
                            }

                            if (!yFrom || !xFrom || !yTo || !xTo) {
                                return;
                            }

                            ctx.save();
                            ctx.strokeStyle = element.borderColor as string;
                            ctx.lineWidth = 2;
                            ctx.setLineDash([4, 4]);
                            ctx.beginPath();
                            ctx.moveTo(xFrom, yFrom);
                            ctx.lineTo(xTo, yTo);
                            ctx.stroke();
                            ctx.restore();
                        }
                    }
                });
            },
        };
        const correctedMaxTicksLimitPlugin = {
            id: 'correctedMaxTicksLimitPlugin',
            beforeUpdate(chart: ChartPluginsOptions) {
                const xScale = chart.scales[chart.config.options.scales.xAxes[0].id];

                if (xScale.options.ticks.maxTicksLimit) {
                    // eslint-disable-next-line func-names
                    xScale.draw = function () {
                        if (xScale.options.ticks.maxTicksLimit) {
                            const { helpers } = Chart;

                            const tl = xScale.options.gridLines.tickMarkLength;

                            helpers.each(xScale.ticks, (label: string, index: number) => {
                                if (index === xScale.ticks.length - 1 || index % (xScale.options.ticks.maxTicksLimit + 1) === 0) {
                                    // @ts-ignore
                                    const xLabelValue = this.getPixelForTick(index, this.options.gridLines.offsetGridLines);
                                    this.ctx.save();
                                    const xPadding = index === xScale.ticks.length - 1 ? 24 : 3;
                                    this.ctx.translate(xLabelValue + this.options.ticks.labelOffset - xPadding, this.top + tl);
                                    this.ctx.textBaseline = 'top';
                                    // eslint-disable-next-line
                                    this.ctx.font = `normal ${Chart.defaults.global.defaultFontSize}px ${Chart.defaults.global.defaultFontFamily}`;
                                    this.ctx.fillStyle = Chart.defaults.global.defaultFontColor;
                                    this.ctx.fillText(label, 0, 0);
                                    this.ctx.restore();
                                }
                            }, xScale);
                        }
                    };
                }
            },
        };

        const canvasFixedTooltipPlugin = {
            id: 'canvasFixedTooltipPlugin',
            afterDatasetsDraw: (chart: ChartPluginsOptions) => {
                const borderColor = '#52A9FF';
                const lineWidth = '1';
                const xOffset = 25;
                const tooltipWidth = 50;

                const { ctx } = chart;

                const xAxis = chart.scales[chart.config.options.scales.xAxes[0].id];
                const labelItemIndex = this.fixedTooltipPosition === null ? xAxis._labelItems.length - 1 : this.fixedTooltipPosition;
                const labelItem = xAxis._labelItems[labelItemIndex];

                const bottomPosition = xAxis.bottom;
                const tooltipX = labelItem.x;

                const tooltipLeft = tooltipX - xOffset;

                ctx.save();
                ctx.strokeStyle = borderColor;
                ctx.lineWidth = lineWidth;
                ctx.beginPath();
                ctx.moveTo(tooltipLeft, bottomPosition);
                ctx.lineTo(tooltipLeft, 0);
                ctx.lineTo(tooltipLeft + tooltipWidth, 0);
                ctx.lineTo(tooltipLeft + tooltipWidth, bottomPosition);
                ctx.lineTo(tooltipLeft, bottomPosition);
                ctx.stroke();
                ctx.restore();
            },
        };

        pluginSet.canvasFixedTooltipPlugin = canvasFixedTooltipPlugin;

        this.plugins.forEach(pluginName => {
            if (pluginSet[pluginName]) {
                this.addPlugin(pluginSet[pluginName]);
            }
        });

        // this.addPlugin(correctedMaxTicksLimitPlugin);
        this.addPlugin(plugin);
        this.addPlugin(dashedLine);
        this.drawChart();
    }

    drawChart() {
        if (this.chartData && this.chartData.datasets && this.chartData.datasets.length !== 0) {
            this.renderChart(this.chartData, this.options);
            this.$emit('afterDraw', this.$data._chart);
        }
    }
}
