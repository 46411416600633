var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container",on:{"mouseleave":function($event){_vm.isTooltip && !_vm.isFreezeTooltip && _vm.disableTooltip()}}},[[(_vm.type === 'line')?_c('LineGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions,"applyPlugins":true,"plugins":this.plugins,"fixedTooltipPosition":_vm.canvasFixedTooltipPosition},on:{"afterDraw":_vm.handleLineChartAfterDraw}}):_vm._e(),(_vm.type === 'horizontal-bar')?_c('HorizontalBarGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions}}):_vm._e(),(_vm.type === 'bar')?_c('BarGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions}}):_vm._e(),(_vm.type === 'rounded-bar')?_c('RoundedBarGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions}}):_vm._e(),(_vm.type === 'doughnut')?_c('Doughnut',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions}}):_vm._e(),(_vm.type === 'scatter')?_c('ScatterGraph',{attrs:{"chartData":_vm.chartData,"options":_vm.updatedOptions}}):_vm._e()],(_vm.isTooltip && _vm.tooltipX)?[_c('div',{ref:"tooltip",staticClass:"my-tooltip",style:({
                left: _vm.tooltipX || undefined,
                top: _vm.tooltipY || undefined,
            }),on:{"click":function($event){return _vm.handleClickTooltip()}}},[_vm._t("tooltip",null,null,{
                    day: _vm.day,
                    chartData: _vm.chartData,
                    freezeTooltip: _vm.freezeTooltip,
                    tooltipModel: _vm.tooltipModel,
                    hoverIndex: _vm.hoverIndex,
                    currentBarColor: _vm.currentBarColor,
                })],2)]:_vm._e(),(_vm.isTooltip && _vm.haveFixedTooltip)?[(_vm.fixPosition)?_c('div',{staticClass:"fixed-tooltip",style:({ left: _vm.fixPosition })}):_vm._e()]:_vm._e(),_c('div',{staticClass:"setupWidth"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }