
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ChartData, ChartOptions, ChartTooltipModel } from 'chart.js';
import RoundedBarGraph from '@/modules/common/components/ui-kit/custom-graph/graph-types/rounded-bar';
import HorizontalBarGraph from './graph-types/horizontal-bar';
import BarGraph from './graph-types/bar';
import LineGraph from './graph-types/line';
import Doughnut from './graph-types/doughnut';
import ScatterGraph from './graph-types/scatter';

let prevTooltipElement: HTMLElement | null = null;

@Component({
    components: {
        LineGraph,
        HorizontalBarGraph,
        BarGraph,
        RoundedBarGraph,
        Doughnut,
        ScatterGraph,
    },
})
export default class CustomGraph extends Vue {
    @Prop({ type: Boolean })
    isTooltip!: boolean;

    @Prop({ required: false, type: Boolean })
    haveFixedTooltip!: boolean;

    @Prop({ default: 0 })
    fixedTooltipDefaultIndex!: number;

    @Prop({ required: true, type: Object as () => ChartData })
    chartData!: ChartData;

    @Prop({ required: false, type: Boolean, default: false })
    moveAlongTheYaxis!: boolean;

    @Prop({ required: false, type: Boolean, default: true })
    intersect!: boolean;

    @Prop({ required: true, type: Object as () => ChartOptions })
    options!: ChartOptions;

    @Prop({ type: Array, default: () => ([]) })
    disabledTooltipDays!: number[];

    // Props below are only used in ranking history chart
    @Prop({ required: false, type: Number, default: 0 })
    tooltipXOffset!: number;

    @Prop({ required: false, type: Number, default: 0 })
    tooltipYOffset!: number;

    @Prop({ required: false, type: Boolean })
    // Fixed tooltip will be drawn via ctx
    isCanvasFixedTooltip?: boolean;

    @Prop({ required: false, type: Number })
    // Fixed tooltip clicked label index
    canvasFixedTooltipPosition?: number;

    @Prop({ type: String, default: 'line' })
    type!: 'line' | 'horizontal-bar' | 'bar' | 'scatter' | 'doughnut' | 'rounded-bar';

    tooltipX: string | null = null;
    tooltipY: string | null = null;
    fixPosition: string | null = null;
    isFreezeTooltip: boolean = false;
    day: string | null = null;
    currentBarColor: string = '#fff';
    hoverIndex: number = -1;
    tooltipModel: ChartTooltipModel | null = null;

    updated() {
        const { tooltip } = this.$refs as { tooltip: HTMLElement };

        if (tooltip !== prevTooltipElement) {
            prevTooltipElement = tooltip;
            this.$emit('tooltipStateChanged', tooltip && tooltip.children[0]);
        }
    }

    handleLineChartAfterDraw(chart: Chart) {
        if (this.haveFixedTooltip) {
            const chartMetaData = chart.getDatasetMeta(0).data;
            const lastItemMetaModel = chartMetaData[chartMetaData.length - 1 - this.fixedTooltipDefaultIndex]._model;
            this.fixPosition = `${this.tooltipXOffset + lastItemMetaModel.x - 15}px`;
        }

        this.$emit('afterDraw', chart);
    }

    freezeTooltip(flag: boolean) {
        this.isFreezeTooltip = flag;
    }

    handleClickTooltip() {
        this.$emit('setDay', this.day);

        if (this.haveFixedTooltip) {
            this.fixPosition = this.tooltipX;
        }
    }

    get updatedOptions() {
        // without this.tooltipXOffset tooltip will not dynamycaly rerender
        // because no direct link to this prop in getter
        // eslint-disable-next-line no-unused-expressions
        this.tooltipXOffset;

        // eslint-disable-next-line no-unused-expressions
        this.tooltipYOffset;

        const commonOptions = {
            animation: {
                duration: 0,
            },
        } as ChartOptions;

        return this.isTooltip
            ? {
                ...commonOptions,
                ...this.options,
                onHover: (e: MouseEvent, activeElements: any) => {
                    if (!activeElements[0]) return;

                    const [chartElement] = activeElements;

                    this.currentBarColor = chartElement._model.backgroundColor;
                    this.hoverIndex = chartElement._index;

                    if (this.options.onHover) {
                        (this.options.onHover as (e: MouseEvent, a: {}[]) => void)(e, activeElements);
                    }
                },
                tooltips: {
                    intersect: !this.intersect,
                    mode: 'index',
                    enabled: false,
                    position: 'nearest',
                    custom: this.showTooltip,
                },
            }
            : {
                ...commonOptions,
                ...this.options,
            };
    }

    get plugins() {
        const selectedPlugins = [] as string[];

        if (this.isCanvasFixedTooltip) {
            selectedPlugins.push('canvasFixedTooltipPlugin');
        }

        return selectedPlugins;
    }

    isDisabledDay() {
        const isHaveDisabledDays = !!this.disabledTooltipDays.length;

        if (!isHaveDisabledDays) {
            return false;
        }

        const isDayDisabled = this.disabledTooltipDays
            .includes(Number(this.day));

        if (!isDayDisabled) {
            return false;
        }

        return true;
    }

    showTooltip(tooltipModel: ChartTooltipModel): void {
        const targetPositionX = `${this.tooltipXOffset + tooltipModel.caretX - 15}px`;
        const targetPositionY = `${tooltipModel.caretY + this.tooltipYOffset}px`;

        this.tooltipModel = tooltipModel;

        if (tooltipModel.opacity === 0 && this.tooltipX !== targetPositionX) {
            this.tooltipX = null;
            this.tooltipY = null;
            return;
        }

        const isDisabled = this.isDisabledDay();

        this.freezeTooltip(false);

        this.tooltipX = isDisabled
            ? null
            : targetPositionX;

        if (this.moveAlongTheYaxis) {
            this.tooltipY = targetPositionY;
        }

        this.day = tooltipModel.title
            ? tooltipModel.title[0]
            : this.day;

        this.$emit('currentDay', this.day);
        this.$forceUpdate();
    }

    disableTooltip(): void {
        this.tooltipX = null;
        this.$emit('currentDay', null);
    }
}
