
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Dialog } from 'element-ui';

export interface DialogOptions {
    appendToBody?: boolean
}

@Component({
    components: {
        Dialog,
    },
})
export default class ModalWrapper extends Vue {
    @Prop({ default: true, type: Boolean })
    private isVisible!: boolean;

    @Prop({ default: true, type: Boolean })
    closeOnClickWrapper?: boolean;

    @Prop({ default: true, type: Boolean })
    private showHeader?: boolean;

    @Prop({ default: () => ({}), type: Object })
    private options!: DialogOptions;

    @Prop({ default: '', type: String })
    private addClass!: String;

    @Prop({ required: false, type: Function })
    private onClose?: () => void;

    triggerClose(): void {
        if (this.onClose) {
            this.onClose();
            return;
        }

        if (this.$route.matched.length > 2) {
            const matchedPath = this.$route.matched[this.$route.matched.length - 2].path;
            const params = Object.keys(this.$route.params);
            let normalizedPath = matchedPath.split('/').map(path => {
                for (let i = 0; i < params.length; i += 1) {
                    if (path === `:${params[i]}` || path === `:${params[i]}?`) {
                        return this.$route.params[params[i]];
                    }
                }
                return path;
            }).join('/');

            if (normalizedPath[normalizedPath.length - 1] === '/') {
                normalizedPath = normalizedPath.substring(0, normalizedPath.length - 1);
            }

            this.$router.push({ path: normalizedPath, query: this.$route.query });
            // this.$router.go(-1);
            // this.$router.push(this.$route.matched[this.$route.matched.length - 2].path);
        } else {
            this.$router.push('/');
        }

        this.$emit('onClose');
    }
}
