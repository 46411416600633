

import {
    Component,
    Vue,
    Prop,
} from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import moment from 'moment';

import Day from '@/modules/common/types/day.type';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';

import type MarketsHistoryService from '@/modules/common/modules/markets-history/markets-history.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type UserService from '@/modules/user/user.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import type MarketsService from '@/modules/markets/markets.service';
import MarketsCommonService, { MarketsCommonServiceS } from '../../markets/markets-common.service';

export interface TableData {
    id: string;
    name: string;
    position: number;
    myHotel: boolean;
    borderColor?: string;
    oldPosition?: number;
    positionDiff?: number;
    promoted?: boolean;
    page?: number;
    link?: string;
}

@Component
export default class RatesPriceHistoryTable extends Vue {
    @inject(KEY.MarketsHistoryService) private marketsHistoryService!: MarketsHistoryService;
    @inject(MarketsCommonServiceS) private marketsCommonService!: MarketsCommonService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.MarketsService) private marketsService!: MarketsService;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    public hiddenGraphs!: { [hid: number]: boolean };

    get isChainPage() {
        const { name } = this.$route;

        if (!name) return false;

        return name.includes('chain.hotel.compset')
            || name.includes('cluster.markets.day-markets')
            || name.includes('chain.markets.day-markets');
    }

    get isOutOfRange() {
        return this.marketsCommonService.isOutOfRange(this.marketsHistoryService.marketsDocument);
    }

    get compsetId() {
        return this.isChainPage
            ? this.$route.params.compsetId
            : null;
    }

    get currentHotelId() {
        return Number(this.$route.params.hotelId) || this.userService.currentHotelId;
    }

    get historyDate() {
        const { lastScanDate, dayIndex } = this.marketsHistoryService;
        const date = new Date(lastScanDate || 0);

        date.setDate(date.getDate() - dayIndex);
        return moment(date).format('DD.MM');
    }

    get scanDate() {
        return this.marketsHistoryService.currentScanDate;
    }

    get days() {
        const { dayIndex } = this.marketsHistoryService;
        return !dayIndex ? 'Last update' : `-${dayIndex} days`;
    }

    get day() {
        return +this.$route.params.day as Day;
    }

    get document() {
        return this.isChainPage
            ? this.clusterService.getMainCompsetData(this.currentHotelId!) as unknown as MarketsDocumentModel
            : this.marketsService.getDocument(this.$route.params.source);
    }

    get promotionLogo() {
        const provider = this.$route.params.source;

        if (/booking/.test(provider)) {
            return this.marketsCommonService.getProgramLogo('booster');
        }

        if (/expedia/.test(provider)) {
            return this.marketsCommonService.getProgramLogo('travel_ads');
        }

        return null;
    }

    get tableData() {
        const { compsetId, document: doc } = this;
        const { marketHistoryHotels, dayIndex } = this.marketsHistoryService;
        const hotelMarket = this.marketsHistoryService.getSuitableMarketByDay(dayIndex);

        if (!marketHistoryHotels || !doc) {
            return null;
        }

        const tablePriceHistoryData = marketHistoryHotels.map((hotelId: number) => {
            if (!hotelMarket) {
                return {} as TableData;
            }

            const isMyHotel = hotelId === this.currentHotelId;
            const currentHotelMarket = hotelMarket[hotelId];
            const name = this.hotelsService.hotelNames[hotelId] || hotelId;

            const oldPosition = !dayIndex
                ? this.marketsCommonService.getOldPosition(this.day, hotelId, doc)
                : null;

            const checkinDate = !dayIndex
                ? this.marketsCommonService.getHotelCheckingDate(this.day, hotelId, doc)
                : null;

            const borderColor = isMyHotel
                ? CURRENT_HOTEL_GRAPH_COLOR
                : this.marketsHistoryService.getHotelColor(hotelId);

            const positionDiff = currentHotelMarket?.position && oldPosition
                ? currentHotelMarket.position - oldPosition
                : null;

            const promoted = !!checkinDate?.promoted;

            const link = !dayIndex
                ? this.marketsCommonService.getLink(this.day, hotelId, doc)
                : null;

            if (!currentHotelMarket) {
                return {
                    id: String(hotelId),
                    name,
                    position: -1,
                    myHotel: isMyHotel,
                    oldPosition,
                    borderColor,
                    positionDiff,
                    promoted,
                    link,
                } as TableData;
            }

            return {
                id: String(hotelId),
                name,
                position: currentHotelMarket.position,
                myHotel: isMyHotel,
                borderColor,
                oldPosition,
                positionDiff,
                promoted,
                page: currentHotelMarket.page,
                link,
            } as TableData;
        });

        return tablePriceHistoryData.sort((a, b) => {
            if (a.position < 0) return 1;
            if (b.position < 0) return -1;
            return a.position - b.position;
        });
    }
}
